import React from 'react';
import { shape, func, string, number } from 'prop-types';
import ImageLogo from '../commons/image-logo';
import MainInfo from './main-info';
import ExtraInfo from './extra-info';

const namespace = 'ui-seller-data-header';

const CTA_FOLLOW = 'do_follow';
const CTA_UNFOLLOW = 'stop_follow';

const getCurrentCtaInfo = follow_info =>
  follow_info.cta_status === CTA_FOLLOW
    ? { status: CTA_FOLLOW, ...follow_info.cta_do_follow }
    : { status: CTA_UNFOLLOW, ...follow_info.cta_stop_follow };

const Header = ({ title, subtitle, reseller, logo, social_media, products, sales, followers, onFollowClick }) => {
  const ctaStatusInfo = followers ? getCurrentCtaInfo(followers) : null;

  return (
    <>
      <div className={`${namespace}__container`}>
        {logo && (
          <div className={`${namespace}__logo-container`}>
            <ImageLogo logo={logo} />
          </div>
        )}
        <div className={`${namespace}__description-container`}>
          <MainInfo
            title={title}
            subtitle={subtitle}
            followers={followers}
            onFollowClick={onFollowClick}
            ctaStatusInfo={ctaStatusInfo}
          />
          <ExtraInfo
            reseller={reseller}
            social_media={social_media}
            products={products}
            sales={sales}
            follow_title={ctaStatusInfo?.title}
          />
        </div>
      </div>
    </>
  );
};

Header.propTypes = {
  title: shape({
    text: string,
    color: string,
    font_size: string,
    font_family: string,
  }).isRequired,
  subtitle: shape({
    icon: shape({}),
    label: shape({
      text: string,
      color: string,
      font_size: string,
    }),
  }),
  reseller: shape({
    text: string,
    color: string,
    font_size: string,
  }),
  logo: shape({
    width: number,
    url: shape({
      src: string,
    }),
  }),
  social_media: shape({
    text: string,
    color: string,
    font_size: string,
    values: shape({}),
  }),
  products: shape({
    text: string,
    color: string,
    font_size: string,
    values: shape({}),
  }),
  sales: shape({
    text: string,
    color: string,
    font_size: string,
    values: shape({}),
  }),
  followers: shape({}),
  onFollowClick: func,
};

Header.defaultProps = {
  subtitle: null,
  reseller: null,
  logo: null,
  social_media: null,
  products: null,
  sales: null,
  followers: null,
  onFollowClick: null,
};

export default Header;
